<template>
    <SectionsNav>
        <template #introduction>
            <a class="nav-link" @click="showSection('presentation')">Обзор</a>
        </template>
        <template #programs>
            <a class="nav-link" @click="showSection('programs')">Программы</a>
        </template>
        <template #campuses>
            <a class="nav-link" @click="showSection('campus')">Кампусы</a>
        </template>
        <template #selectSection>
            <select id="select-mobile-menu" class="form-select form-select-lg" v-model="this.currentSection">
                <option value="presentation">Обзор</option>
                <option value="programs">Программы</option>
                <option value="campus">Кампусы</option>
            </select>
        </template>
    </SectionsNav>
    
    <Obzor v-if="currentSection === 'presentation'" ref="presentation" class="presentation">
        <template #title>
            Обзор
        </template>
        <template #description> 
            ITM Paris - частное высшее техническое учебное заведение. Компания ITM Paris, основанная в 1985 году, является 
            эталоном профессионального обучения макияжу.
            <br>
            Школа готовит специалистов для кино, аудиовизуальной индустрии, моды, развлечений, рекламы и красоты.
            Учеба в ITM - это не только обучение технике, но и подготовка к профессии. Обучение носит междисциплинарный характер. Оно объединяет области, связанные с макияжем, такие как парикмахерское дело, укладка... Это позволяет студентам понять профессии, с которыми им потребуется сотрудничать, понять ограничения и требования и принять их во внимание в своей работе. 
            <br>
            Креативность и открытость к художественной деятельности воспитываются на курсах по рисунку, живописи, моделированию, истории портретной живописи и, следовательно, красоты на протяжении веков.
            Обучение английскому языку, и особенно лексике индустрии макияжа, способствует интеграции студентов в мультикультурные команды во время международных кинопроизводств или показов мод.
            Семинары по чтению и анализу сценария позволяют студентам более профессионально подготовиться к съемке.
        </template>
        <template #orientation>
            Bachelor & Master
        </template>
        <template #youtube>
            <iframe width="450" height="315" src="https://www.youtube.com/watch?v=N9WvGNxdyGg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </template>
        <template #speciality>
            Fashion & design
        </template>
    </Obzor>


    <section v-if="currentSection === 'programs'" ref="programs" class="programs">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 title-main">
                            <h1>Программы</h1>
                        </div>
                    </div>
                    
                    <div class="row program-select-wrapper">
                        <div class="col-12 col-lg-4">
                            <select v-model="program" ref="programSelector" class="form-select form-select-lg">
                                <option disabled value="">Выбрать программу</option>
                                <option value="mmb">Bachelor Maquilleur Mode & Beauté </option>
                                <option value="mce">Bachelor Maquilleur cinéma & effets spéciaux </option>
                                </select>
                        </div>
                    </div>
                    
                    <Program :url="''" v-if="this.program === 'mmb'" id="mmb">
                        <template #title>
                            Bachelor Maquilleur Mode & Beauté 
                        </template>
                        <template #description>
                            <p>
                                Бакалавр Fashion & Beauty готовит будущих визажистов. Обучение позволяет им использовать свой талант на показах мод, престижных мероприятиях и фотосессиях для прессы или рекламы.
                            </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                            французский 
                        </template>
                        <template #cost>
                            8.190 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                            французский уровня B2, досье, диплом о среднем образовании.
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                    <Program :url="''" v-if="this.program === 'mce'" id="mce">
                        <template #title>
                            Bachelor Maquilleur cinéma & effets spéciaux  
                        </template>
                        <template #description>
                            <p>
                                Это обучение позволяет студентам овладеть техникой макияжа для кино и  телевидения. Их также обучают технике макияжа со спецэффектами и использованию различных материалов.
                            </p>
                        </template>
                        <template #duree>
                            3 года
                        </template>
                        <template #language>
                             французский
                        </template>
                        <template #cost>
                            8.190 евро/год
                        </template>
                        <template #campus>
                            Париж
                        </template>
                        <template #requirements>
                             французский уровня B2, досье, диплом о среднем образовании.
                        </template>
                        <template #start-time>
                            Октябрь
                        </template>
                    </Program>

                </div>
            </div>
        </div>
    </section>

    <CampusMain v-if="currentSection === 'campus'" ref="campus" class="campus">
        <template #campuses>
            <Campus>
                <template #localisation>
                    Париж
                </template>
                <template #description>
                    Школа расположена в 5-м округе Парижа.
                </template>
            </Campus>
        </template>
    </CampusMain>

</template>
<script>
import Program from '@/components/schools/slots/Program.vue'
import CampusMain from '@/components/schools/slots/CampusMain.vue'
import SectionsNav from '@/components/schools/slots/SectionsNav.vue'
import Obzor from '@/components/schools/slots/Obzor.vue'
import Campus from '@/components/schools/slots/Campus.vue'

export default {
    data(){
        return {
            currentSection:"presentation",
            program : ""
        }
    },
    components:{
        Program,
        CampusMain,
        SectionsNav,
        Obzor,
        Campus
    },
    methods:{
        showSection(section){
            this.currentSection = section;
            this.desktopScroll();
        },
        desktopScroll(){
            let scrollToElement = document.querySelector(".menu")
            scrollToElement.scrollIntoView({behavior: "smooth", block: "start"});
        }
    }
}
</script>