<template>
    <Introduction :url="'/brochure/itm'" :class="'introduction-itm'">  
        <template #title>
            Itm
        </template>
        <template #speciality>
            Fashion & Design
        </template>
        <template #min-duration>
            От 1 год
        </template>
        <template #cost>
            От 9.490€/год
        </template>
        <template #campus>
            Париж
        </template>
    </Introduction>  
</template>
<script>
import Introduction from '@/components/schools/slots/Introduction.vue'
export default {
    components:{
        Introduction
    }
}
</script>